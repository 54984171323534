import React from 'react';
import './Column.css';

const ReverseColumn = ({ ms }) => {

	// Our tempo divisions. Should probably be a constant elsewhere
	const divisions = [1, 2, 4, 8, 16, 32, 64, 128];

	const rows = divisions.map((division, index) => {

    // So this time it's in reverse
    let tempo = 60 / (ms / 1000) * (4 / division);

    // Round to 4 decimal places
    tempo = Math.round( tempo * 10000 ) / 10000;
		
		const trClass = tempo < 60 || tempo > 180 ? 'unlikely' : '';
		
		return tempo > 10 && tempo < 300 ? (
			<tr key={index} className={trClass}>
				<td>1/{division}</td>
				<td>{tempo}</td>
			</tr>
		) : null;
	});

	return (
		<div className="column">
			<table className="table">
				<thead>
					<tr>
						<th>Division</th>
						<th>Tempo</th>
					</tr>
				</thead>
				<tbody>
					{rows}
				</tbody>
			</table>
		</div>
	)
}

export default ReverseColumn;