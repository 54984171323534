import React, {Fragment, useState} from 'react';
import NumericInput from '../Common/NumericInput';

const HzToMs = () => {

  const [hz, setHz] = useState();
  let content;

  if ( hz && hz > 0 ) {

    let value = Math.round( (1000 / hz) * 10000 ) / 10000;
    
    content = (
      <span className="large-text">
        {value}ms
      </span>
    );
  } else {
    content = (
      <p class="intro">Converts frequency to milliseconds - good for tuning flangers and chorus effects, and creating tones from pure delays.</p>
    );
  }
  
  return (
    <Fragment>
      <div className="input-ui">
        <NumericInput
          value={hz}
          setValue={setHz}
          label="Hz"
          placeholder="Hz"
        />
      </div>
      <div className="content">
        {content}
      </div>
    </Fragment>
  )
}

export default HzToMs;