import React from 'react';
import './Column.css';

const Column = ({ tempo, roundValues, factor = 1 }) => {

	// Our tempo divisions. Should probably be a constant elsewhere
	const divisions = [1, 2, 4, 8, 16, 32, 64, 128];

	const rows = divisions.map((division, index) => {

		const delayTime = ((60/tempo * 1000 * 4) / division) * factor;
		const value = roundValues ? Math.round( delayTime ) : Math.round( delayTime * 100 ) / 100;

		return (
			<tr key={index}>
				<td>1/{division}</td>
				<td>{value}</td>
			</tr>
		)
	});

	return (
		<div className="column">
			<table className="table">
				<thead>
					<tr>
						<th>Note</th>
						<th>Delay (ms)</th>
					</tr>
				</thead>
				<tbody>
					{rows}
				</tbody>
			</table>
		</div>
	)
}

export default Column;