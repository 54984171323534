export const Map = {
  STRAIGHT: 1,
  DOTTED: 1.5,
  TRIPLET: 4/3,
}

export const Mode = {
  BPM_MS: 0,
  MS_BPM: 1,
  MS_HZ: 2,
  HZ_MS: 3,
  NOTES: 4,
  TEMPO: 5,
}

export const PitchTempoMode = {
  FASTER: 0,
  SLOWER: 1,
  TARGET: 2,
}

export const TWO_TWELFTH_ROOT = Math.pow(2, 1/12);
export const A_440 = 440;

export const fixed = (input, places = 2) => {
  return Math.round( input * Math.pow(10, places) ) / Math.pow(10, places);
}

export const sign = (input) => {
  return input > 0 ? `+${input}` : input;
}