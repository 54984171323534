import React, {useState, useEffect} from 'react'
import TempoInput from '../TempoInput/TempoInput'
import { fixed, sign, PitchTempoMode } from '../../constants'
import Buttons from '../Buttons/Buttons'

const PitchToTempo = () => {
  
  let initialTempo = window.localStorage.getItem('tempo')
  let initialTargetTempo = window.localStorage.getItem('targetTempo')

  let initialDisplay = Number(window.localStorage.getItem('display')) || PitchTempoMode.FASTER; // This is OK since that mode is 0

  // if (isNaN(initialTempo)) {
  //   initialTempo = false;
  // }

  const [currentTempo, setCurrentTempo] = useState(initialTempo)
  const [targetTempo, setTargetTempo] = useState(initialTargetTempo)
  const [display, setDisplay] = useState(initialDisplay)

  // Store locally
  useEffect(() => {
    window.localStorage.setItem('tempo', currentTempo)
    window.localStorage.setItem('targetTempo', targetTempo)
    window.localStorage.setItem('display', display)
  })

  let content
  let exact
  let tableRows = []

  if (currentTempo >= 25) {

    // Are we in exact mode and have a target tempo?
    if (display === PitchTempoMode.TARGET && (targetTempo >= 25)) {

      // Work out the exact semitones to the target
      const fraction = targetTempo / currentTempo;
      exact = 12 * Math.log2(fraction);

      // Round Up
      const roundUpSemitones = Math.ceil(exact);
      const roundUpTempo = fixed(Math.pow( 2, roundUpSemitones/12 ) * currentTempo);
      
      // If we have a value other than 0, show tempos that work
      if (roundUpSemitones !== 0) {
        tableRows.push(
          <tr>
            <td>{roundUpTempo}</td>
            <td>{sign(roundUpSemitones)}</td>
          </tr>
        )
      }

      // Round Down
      const roundDownSemitones = Math.floor(exact);
      const roundDownTempo = fixed(Math.pow( 2, roundDownSemitones/12 ) * currentTempo);
      
      if (roundDownSemitones !== 0 && roundDownSemitones !== roundUpSemitones) {
        tableRows.push(
          <tr>
            <td>{roundDownTempo}</td>
            <td>{sign(roundDownSemitones)}</td>
          </tr>
        )
      }
    } else if (display !== PitchTempoMode.TARGET) {
      
      // Show 24 steps up or down
      tableRows = Array.from(Array(24).keys()).map((value, index) => {
        const f = (display === PitchTempoMode.FASTER) ? 1 : -1;
        return (
          <tr key={`tr${index}`}>
            <td>{fixed(Math.pow( 2, ((1 + value) * f)/12 ) * currentTempo)}</td>
            <td>{sign((index + 1) * f)}</td>
          </tr>
        )
      })
    }

    // Now build the content from that
    content = tableRows.length ? (
      <div className="content">

        {/* Show the exact shift if we know it */}
        {display === PitchTempoMode.TARGET && (currentTempo >= 25) && (targetTempo >= 25) && (
          <div className="section">
            <p>Exact Shift: {sign(fixed(exact, 4))} semitones</p>
          </div>
        )}

        <div className="section">
          <h3>Tempos that will work</h3>
          <table className="table">
            <thead>
              <tr>
                <td>Tempo (bpm)</td>
                <td>Shift (semitones)</td>
              </tr>
            </thead>
            <tbody>
              {tableRows}
            </tbody>
          </table>
        </div>
      </div>
    ) : false

  } else {
    content = (
      <div className="content intro">
        <p>Enter a tempo to see faster / slower tempos that give a semitone-accurate pitch. You can also enter a specific target tempo and it will tell you tempos near that target that will work to keep your track in a chromatic key</p>
      </div>
    );
  }

  const buttonItems = [
    'Faster',
    'Slower',
    'Target',
  ]
  
  return (
    <div>
      
      {/* Always show the main Tempo input */}
      <div className="options">
        <div className="input-group">
          <TempoInput
            placeholder="Tempo"
            tempo={currentTempo}
            setTempo={setCurrentTempo}
          />

          {(display === PitchTempoMode.TARGET) && (currentTempo >= 25) && (
            <TempoInput
              placeholder="Target"
              tempo={targetTempo}
              setTempo={setTargetTempo}
              autoFocus={false}
            />
          )}
        </div>

        {currentTempo > 25 && (
          <Buttons
            value={display}
            setValue={setDisplay}
            items={buttonItems}
          />
        )}
      </div>

      {content}
    </div>
  )
}

export default PitchToTempo;