import React from 'react';
import './Column.css';

const Column = ({ ms }) => {

	const divisions = [1, 2, 3, 4, 6, 8, 12, 16, 24];

	const decimalPlaces = 4;
  const f = Math.pow(10, decimalPlaces);
	
	const rows = divisions.map((division, index) => {

		let value = Math.round( (1000 * division / ms) * f ) / f;

		if ( value % 1000 === 0 ) {
			value /= 1000;
			value += 'kHz';
		}

		return (
			<tr>
				<td>{division}</td>
				<td>{value}</td>
			</tr>
		)
	});

	return (
		<div className="column">
			<table className="table">
				<thead>
					<tr>
						<th>n</th>
						<th>Hz</th>
					</tr>
				</thead>
				<tbody>
					{rows}
				</tbody>
			</table>
		</div>
	)
}

export default Column;