import React, {Fragment, useState} from 'react';
import NumericInput from '../Common/NumericInput';
import ColumnMsHz from '../Column/Column_MsToHz';

const MsToHz = () => {

  const [ms, setMS] = useState();
  let content;

  if ( ms && ms > 0 ) {
    content = (
      <ColumnMsHz ms={ms} />
    );
  } else {
    content = (
      <p class="intro">Converts millisecond values to frequency. Good for working out synced LFOs.</p>
    );
  }
  
  return (
    <Fragment>
      <div className="input-ui">
        <NumericInput
          value={ms}
          setValue={setMS}
          label="ms"
          placeholder="ms"
        />
      </div>
      <div className="content">
        {content}
      </div>
    </Fragment>
  )
}

export default MsToHz;