import React, {useState} from 'react';
import ColumnMsToBpm from '../Column/Column_MsToBpm';
import NumericInput from '../Common/NumericInput';

const MsToBpm = () => {
  
  const [ms, setMS] = useState();

  let content;

  if ( ms ) {
    content = (
      <div className="content">
        <ColumnMsToBpm ms={ms} />
      </div>
    );
  } else {
    content = (
      <div className="content intro">
        <p>Given a millisecond value, this will tell you possible tempos that match. For example, if you enter 250ms that would give a tempo of 120bpm if you were counting 1/8th notes, but 60bpm for 1/16th notes.</p>
      </div>
    );
  }
  
  return (
    <div>
      <div className="input-ui">
        <NumericInput
          value={ms}
          setValue={setMS}
          label="ms"
          placeholder="ms"
        />
      </div>
      {content}
    </div>
  )
}

export default MsToBpm;