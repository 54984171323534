import React from 'react';
import NumericInput from '../Common/NumericInput';

const TempoInput = ( {tempo, setTempo, setRoundValues = null, placeholder = "", autoFocus = true, roundValues = false} ) => {

  let btnClassNm = "btn";
  if (roundValues) {
    btnClassNm += " active";
  }

  if ( isNaN(tempo) ) {
    btnClassNm += " hidden";
  }

  return (
  
    <div className="input-ui">
      <NumericInput
        value={tempo}
        setValue={setTempo}
        placeholder={placeholder || "bpm"}
        label="bpm"
        autoFocus={autoFocus}
      />
      {roundValues && <button
        className={btnClassNm}
        onClick={() => {
          setRoundValues( !roundValues );
        }}  
      >
        Nearest ms
      </button>}
    </div>
  )
}

export default TempoInput;