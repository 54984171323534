import React, {useState} from 'react';
import {TWO_TWELFTH_ROOT} from '../../constants';
import Buttons from '../Buttons/Buttons';
// A440 is A4

const NotesScreen = () => {

  const [octave, setOctave] = useState(4);
  const [enharmonicShift, setEnharmonicShift] = useState('sharp');

  const enharmonicOptions = {
    'sharp' : ['C', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#', 'A', 'A#', 'B'],
    'flat' : ['C', 'Db', 'D', 'Eb', 'E', 'F', 'Gb', 'G', 'Ab', 'A', 'Bb', 'B'],
  }
  const notes = enharmonicOptions[enharmonicShift];
  let rows = [];

  const toggleEnharmonic = () => {
    setEnharmonicShift(enharmonicShift === 'sharp' ? 'flat' : 'sharp');
  }

  for( let i = notes.length - 1; i >= 0; i--) {
    const note = notes[i];
    const midiNoteNumber = 12 + (octave * 12) + i;
    if ( midiNoteNumber > 127 ) {
      continue;
    }
    const distToA440 = midiNoteNumber - 69;
    let hz = (distToA440 === 0) ? 440 : 440 * Math.pow( TWO_TWELFTH_ROOT, distToA440 );
    const ms = (1000 / hz);

    // Build the rows
    rows.push(
      <tr key={i}>
        <td onClick={toggleEnharmonic}>{note}{octave}</td>
        <td>{midiNoteNumber}</td>
        <td>{hz.toFixed(2)}</td>
        <td>{ms.toFixed(2)}</td>
      </tr>
    );
  }

  const buttonItems = Array.from( Array(10).keys() );

  return (
    <div className="content">
      <div className="numeric-input">
        <span className="tiny-text">OCTAVE</span>
        <Buttons
          value={octave}
          setValue={setOctave}
          items={buttonItems}
        />
      </div>
      <div className="column">
        <table className="table">
          <thead>
            <tr>
              <th>Note</th>
              <th>MIDI</th>
              <th>Hz</th>
              <th>ms</th>
            </tr>
          </thead>
          <tbody>
            {rows}
          </tbody>
        </table>
      </div>
      <p className="mt intro">To convert from sharps to flats view, just tap on any note name on the left</p>
    </div>
  );
}

export default NotesScreen;

/*

21 = A0 
127 = G9

A4 = 440.0

*/
