import React from 'react';
import './NumericInput.css';

const NumericInput = ( {value, setValue, label, autoFocus = false, placeholder = "bpm"} ) => {

  return (
    <div className={`${label.toLowerCase()}-input`}>
      <input
        type="number"
        step="1"
        placeholder={placeholder}
        className="input"
        autoFocus={autoFocus}
        value={value}
        onChange={event => setValue(event.target.value)}
      />
      <span className="addon mr">{label}</span>
    </div>
  )
}

export default NumericInput;