import React, { useState } from 'react'
import ModeSelector from '../ModeSelector/ModeSelector';
import { Mode } from '../../constants';
import {
	BpmToMsScreen,
	MsToBpmScreen,
	MsToHzScreen,
	HzToMsScreen,
	NotesScreen,
	TempoScreen,
} from '../Screens';
import './App.css';

export default function App() {
	const [mode, setMode] = useState( Mode.TEMPO );

	let screen;

	switch( mode ) {
		case Mode.BPM_MS :
			screen = <BpmToMsScreen />
			break;
		case Mode.MS_BPM :
			screen = <MsToBpmScreen />
			break;
		case Mode.MS_HZ :
			screen = <MsToHzScreen />
			break;
		case Mode.HZ_MS :
			screen = <HzToMsScreen />
			break;
		case Mode.NOTES :
			screen = <NotesScreen />
			break;
		case Mode.TEMPO :
			screen = <TempoScreen />
			break;
		default :
			break;
	}

  return (
		<div className="app">
			<div className="app-header">
				<h1>Synculator</h1>
				<ModeSelector 
					mode={mode}
					setMode={setMode}
				/>
			</div>
			<div className="app-main">
				{screen}
			</div>
			<div className="app-footer">
				<span className="cwf">Built by <a href="https://codewithfeeling.com" rel="noopener noreferrer" target="_blank">Code With Feeling</a> © 2019</span>	
			</div>
    </div>  
  )
}