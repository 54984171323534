import React, {useState} from 'react';
import TempoInput from '../TempoInput/TempoInput';
import Buttons from '../Buttons/Buttons';
import Column from '../Column/Column';

const BpmToMs = () => {

  const columnMap = [
    {title: 'Straight', factor: 1},
    {title: 'Dotted', factor: 1.5},
    {title: 'Triplets', factor: 4/3},
  ];

  const [tempo, setTempo] = useState();
	const [roundValues, setRoundValues] = useState( false );
  const [index, setIndex] = useState( 0 );

  // Make sure we have a tempo before showing buttons & table
	const noTempo = isNaN(tempo) || !tempo || tempo <= 10;

	// Which column to show?
	const { title, factor } = columnMap[ index ];
    
  let content;

	if ( ! noTempo ) {
    content = (
      <div className="content">
        <Buttons
          items={['Straight', 'Dotted', 'Triplets']}
          value={index}
          setValue={setIndex}
        />
        <Column
          title={title}
          tempo={tempo}
          factor={factor}
          roundValues={roundValues}
        />
      </div>
    );
  } else {
    content = (
    <div className="content intro">
      <p>Calculates millisecond delay times from a tempo. You can choose between straight, dotted and triplet notes.</p>
    </div>
    );
  }
  
  return (
    <div>
      <TempoInput
        tempo={tempo}
        setTempo={setTempo}
        roundValues={roundValues}
        setRoundValues={setRoundValues}
      />
      {content}
    </div>
  )
}

export default BpmToMs;