import React from 'react';
import './ModeSelector.css';

const ModeSelector = ({mode, setMode}) => {

  const optionFactory = (option, index) => {
    return (
      <option key={index} value={index}>{option}</option>
    );
  };

  const options = [
    'BPM to MS',
    'MS to BPM',
    'MS to Hz',
    'Hz to MS',
    'Notes',
    'Tempo to Pitch'
  ];

  return (
    <div className="mode-select">
      <select
        defaultValue={mode}
        className="selector"
        onChange={(event) => {setMode(Number(event.target.value))}}>
        {options.map(optionFactory)}
      </select>
    </div>
  );
}

export default ModeSelector;