import React from 'react';
import './Buttons.css';

const Buttons = ({ value, setValue, items }) => {

  const buttonFactory = (title, i) => {
    const classNm = ( i === value ) ? "btn active" : "btn";
    return (
      <button key={i} className={classNm} onClick={() => setValue(i)}>{title}</button>
    );
  };

  let classNm = "buttons";
  if ( items.length > 4 ) {
    classNm += " compact";
  }

  return (
    <div className={classNm}>
      {items.map(buttonFactory)}
    </div>
  );
}

export default Buttons;